import React, { useContext } from "react";
import Fade from "@material-ui/core/Fade";
import { Menu, MenuItem } from "@material-ui/core";
import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";
import DialogBoxButton from "../DialogBoxButton";
import { CoreDialogBoxProps } from "../DialogBox";
import styles from "./DropdownMenu.module.scss";
import PermissionContext, { 
  useUserPermission,
} from "../../routing/contexts/PermissionContext";
import PermissionArea from "../../Authentication/model/PermissionArea";
import Permission from "../../Authentication/model/Permission";

export enum DropdownMenuItemType {
  LINK,
  BUTTON,
  BUTTON_CONFIRM,
}

interface DropDownMenuLink extends CoreDropdownMenuItem {
  readonly type: DropdownMenuItemType.LINK;
  readonly link: string;
  readonly state?: object;
}

interface DropdownMenuButton extends CoreDropdownMenuItem {
  readonly type: DropdownMenuItemType.BUTTON;
  readonly onClick: () => void;
}

interface DropDownMenuButtonConfirm
  extends CoreDialogBoxProps,
    CoreDropdownMenuItem {
  readonly type: DropdownMenuItemType.BUTTON_CONFIRM;
}

interface CoreDropdownMenuItem {
  readonly label: string;
  readonly icon: JSX.Element;
  readonly hide?: boolean;
  readonly permissionRequired: {
    readonly area?: PermissionArea;
    readonly permission: Permission;
  }[];
}

export type DropdownMenuItem =
  | DropDownMenuLink
  | DropdownMenuButton
  | DropDownMenuButtonConfirm;

interface DropdownMenuProps {
  readonly target: HTMLElement | null;
  readonly onClose: () => void;
  readonly items: DropdownMenuItem[];
}

const renderLink = (item: DropDownMenuLink) => {
  const [link, search] = item.link.split("?");
  const toData = {
    pathname: link,
    state: item.state,
    search,
  };
  return (
    <MenuItem
      className={styles.menuItem}
      to={toData}
      component={React.forwardRef<HTMLAnchorElement, LinkProps>(
        (props, ref) => {
          // eslint-disable-next-line react/prop-types
          const { className } = props;
          return (
            <Link
              {...props}
              innerRef={ref}
              ref={ref}
              className={clsx(className, styles.link)}
            />
          );
        }
      )}
    >
      {item.icon} {item.label}
    </MenuItem>
  );
};

const renderButton = (item: DropdownMenuButton) => (
  <MenuItem
    className={clsx(styles.menuItem, styles.button)}
    onClick={item.onClick}
  >
    {item.icon} {item.label}
  </MenuItem>
);

const renderConfirmButton = (item: DropDownMenuButtonConfirm) => {
  const openButton = (openDialog: () => void) => (
    <MenuItem
      className={clsx(styles.menuItem, styles.button)}
      onClick={openDialog}
    >
      {item.icon} {item.label}
    </MenuItem>
  );

  const {
    onConfirm,
    acceptButtonText,
    declineButtonText,
    dialogHeader,
    children,
  } = item;
  return (
    <DialogBoxButton
      onConfirm={onConfirm}
      acceptButtonText={acceptButtonText}
      declineButtonText={declineButtonText}
      dialogHeader={dialogHeader}
      openButton={openButton}
    >
      {children}
    </DialogBoxButton>
  );
};
const DropdownMenu = ({ target, onClose, items }: DropdownMenuProps) => {
  const permissionContext = useContext(PermissionContext) as PermissionArea;
  const userPermissions = useUserPermission();
  const filteredItems = items
    .filter(({ permissionRequired, hide }) => {
      if (hide) return false;
      if (permissionRequired.length === 0) return true;
      return permissionRequired.some(
        ({ permission, area: permissionTarget }) => {
          let userPermissionsForTarget =
            userPermissions[permissionTarget ?? permissionContext] ?? [];
          if (permissionContext === PermissionArea.SUB_OFFICE) {
            // Making sure CBs are granted full access to their sub-offices.
            userPermissionsForTarget = [
              ...userPermissionsForTarget,
              ...userPermissions[PermissionArea.CB],
            ];
          }
          return userPermissionsForTarget.includes(permission);
        }
      );
    })
    .map((item) => {
      switch (item.type) {
        case DropdownMenuItemType.LINK:
          return renderLink(item);
        case DropdownMenuItemType.BUTTON:
          return renderButton(item);
        case DropdownMenuItemType.BUTTON_CONFIRM:
          return renderConfirmButton(item);
        default:
          return null;
      }
    });
  return (
    <Menu
      className={styles.dropdownMenu}
      anchorEl={target}
      keepMounted
      open={Boolean(target)}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      {filteredItems.length > 0 ? (
        filteredItems
      ) : (
        <MenuItem disabled>No options available</MenuItem>
      )}
    </Menu>
  );
};

export default DropdownMenu;

import React, { useState } from "react";
import Column from "../../../../components/DataTable/models/Column";
import { LinkText } from "../../../../components/Common/hyperlinks";
import VerifyRow from "../models/VerifyRow";
import { VerifyUrls } from "../../index";
import { Checkbox } from "@material-ui/core";
import FavCheckbox from "../../FavouritingAudits";
import  auditSharingPermission from "../../../../../src/Enums/AuditSharingPermission";
//import Icon, { IconType } from "../../../../components/Icon";
import { IconButton } from "@material-ui/core";
import { ArrowDownwardOutlined, ArrowDownwardRounded ,} from "@material-ui/icons";
import downloadCertificate from "../../http/downloadCertificate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { dispatch } from "../../../../components/App/App";
import setError from "../../../../store/error/actions/setError";
const verifyColumns: Column<VerifyRow>[] = [
  {

    title: "Audit Id",
    field: "Id",
    render: (rowData: VerifyRow) => (
      
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
        <LinkText link={VerifyUrls.verifyView(rowData.Id)} text={rowData.Id} />
      ):"")
    
  },

  { title: "Site",
     field: "SiteName",
     render: (rowData: VerifyRow) => (
      
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
       rowData.SiteName
      ):"") 
   },

  {
    title: "Site Code",
    field: "SiteId"
    
  },
  {
    title: "City",
    field: "City",
    filtering: true,
    render: (rowData: VerifyRow) => (
      
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
       rowData.City
      ):"") 
  },

  {
    title: "Country",
    field: "Country",
    filtering: true,
    render: (rowData: VerifyRow) => (
      
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
       rowData.Country
      ):"") 
  },

  {
    title: "Certification Body",
    field: "CertificationBody",
    render: (rowData: VerifyRow) => (
      
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
       rowData.CertificationBody
      ):"") 
 
  },

  {
    title: "Standard",
    field: "Standard",
    render: (rowData: VerifyRow) => (
      
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
       rowData.Standard
      ):"") 
  },
  {
    title: "Grade",
    field: "Grade",
    render: (rowData: VerifyRow) => (
      
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
       rowData.Grade
      ):"") 
  },
  {
    title: "Issue Date",
    field: "IssueDate",
    type: "date",
    render: (rowData: VerifyRow) => (
      
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
       rowData.IssueDate
      ):"") 
  },
  {
    title: "Expiry Date",
    field: "ExpiryDate",
    type: "date",
    render: (rowData: VerifyRow) => (
      
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
       rowData.ExpiryDate
      ):"") 
  },
  {
    title: "Certification Status",
    field: "CertificationStatus",
  },

  {
    title: "Favourites",
    field: "IsFavourited",
    render: (rowData: VerifyRow) => (
      (rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
      <FavCheckbox id={rowData.Id} fav={rowData.IsFavourited} /> 

    ):"")
  
  
  
  )
    
  },
  {
    title: "",
    field: "IsDownloaded",
    render: (rowData: VerifyRow) => 
      (rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF &&
      rowData.CertificationStatus ===  "Certificated" ? (
  
  <IconButton
  onClick={()=>
  {
    if(rowData.HasCertificate === true)
    {
    downloadCertificate(rowData.Id);
    }
    else
    {
      dispatch(
        setError(
          "No certificate is currently available for this audit record"
        )
      );
    }
  }
  }
>
  <FontAwesomeIcon icon={faDownload} />
</IconButton>

    ):"")
  },

];

export default verifyColumns;
